<template>
  <!-- 复制的 Home.vue 组件 -->
  <el-container
    id="home_content"
    class="home_content"
    :class="{ isShowprobation2: isShowprobation }"
    v-loading="loading"
  >
    <el-header class="settingContent">
      <topMenu
        :applicationName="applicationName"
        :show-menus="showMenus"
        :moreMenus="moreMenus"
        :applicationarr="applicationarr"
        :menupostion="menupostion"
        :isShowTabAllMenu="isShowTabAllMenu"
        setting="setting"
        @clickshowMenu="clickshowMenu"
        :allmenulist="allmenulist"
        @deletesurplus="deletesurplus"
        @changeshowmenu="changeshowmenu"
        @changeshowmenus="changeshowmenus"
        @tabapplication="tabapplication"
        @canceltemporary="canceltemporary"
        @Refreshapplicationview="Refreshapplicationview"
        ref="topMenu"
        :isShowMoreMenu="false"
      >
      </topMenu>
      <topTitle :allmenulist="quickAdd" :logourl="logourl" />
    </el-header>
    <el-main id="full-screen">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </el-main>
  </el-container>
</template>

<script>
import TopMenu from "@/views/header/menu.vue";
import topTitle from "@/views/header/topTitle.vue";
import * as LayoutApi from "./api.js";
import VueCookies from "vue-cookies";
import Bus from "@/mixin/bus.js";
import throttle from "lodash.throttle";
import { getDashboardList } from "../../systemSettingsApi.js";

// 设置页顶部菜单
import { getPartnerTabList } from "./js/PartnerCloudMethods";
import $i18n from "@/utils/i18n"; //多语言
export default {
  name: "home",
  components: {
    TopMenu,
    topTitle,
  },
  data() {
    return {
      isShowTabAllMenu: false,
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      quickAdd: [], // 快速新建menu
      showMenus: [], //顶部横向全部菜单
      moreMenus: [],
      applicationarr: [], //应用程序列表哦
      allmenulist: [],
      offsetWidth: "",
      menupostion: true,
      loading: false,
      startmenu: {},
      menuprops: "",
      applicationName: $i18n.t("label.emailsync.form.seversetting"), //设置
      userurl: "",
      RefreshSelect: false,
      logourl: "",
      // tabIds: [
      //   //日历
      //   "acf20200615event",
      //   //日历管理
      //   "acf20208AD53CC8sLvgf",
      //   //同事圈
      //   "acf201200141050RgtYi",
      //   //邮件
      //   "acf20191021FeiAUjdys",
      //   //销售漏斗
      //   "acf2015030630xsld",
      //   //文件
      //   "acf20171206fileUjdys",
      //   //价格手册
      //   "acf2020110PriceBooks",
      //   //潜在客户公海池
      //   "acf202011leadmksea",
      //   //客户公海池
      //   "acf202011accmksea",
      //   //仪表板
      //   "acf100009",
      //   //短信
      //   "acf20201216smslist",
      //   //标情发现
      //   "acf2020340C93FD7chBP",
      //   //小组
      //   "acf20190807sgeAUjdys",
      //   //派工平台
      //   "acfFieldServiceSystb",
      //   //预测
      //   "acf2013121431foreca",
      //   //人员资源日历
      //   "acf20219D0E7E25g4uUv",
      //   //市场活动表单
      //   "acf2021070mcforms",
      //   //社交媒体
      //   "acf2021070mcsocial",
      //   //订货平台
      //   "acf20210713orderpro1",
      // ],
      isShowprobation: this.$store.state.remaining, //是否显示剩余时间
    };
  },
  //销毁监听刷新事件
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
  //获取浏览器当前窗口宽度
  mounted() {
    //获取当前用户动态域名
    this.userurl = this.$cookies.get("domainName");
    //判断是否为伙伴云用户
    if (this.Partnercloud) {
      getPartnerTabList();
    } else {
      this.getapplication();
    }
    this.offsetWidth = document.body.offsetWidth;
    Bus.$on("windowResize", (offsetHeight) => {
      this.offsetWidth = offsetHeight;
    });
    //监听页面刷新挂载事件
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  watch: {
    $route(to) {
      //如果是标准对象列表页则给对应的选项卡加样式
      if (to.params.objId) {
        let isShowId = this.showMenus.filter((item) => {
          return item.objId == to.params.objId;
        });
        if (isShowId.length != 0) {
          this.$Bus.$emit("change-style", isShowId[0].id);
          sessionStorage.setItem("applicationTab", isShowId[0].id);
        } else {
          this.clearshowMenu();
          this.clearmoreMenu();
        }
      } else {
        return;
      }
    },
  },
  methods: {
    // 仪表板跳转前调用仪表板最近查看接口，如果有数据跳转到仪表板页面，无数据跳转到仪表板文件夹列表页
    async getDashboardList() {
      let params = {
        name: "", //搜索内容
        dashboardfolderid: "recentDashboard",
        islightning: "true",
        page: "1",
        pageSize: "10000",
      };
      let data = await getDashboardList(params);
      if (data.data.listDashboard && data.data.listDashboard.length > 0) {
        let id = data.data.listDashboard[0].id;
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${id}`,
          query: {},
        });
      } else {
        this.$router.push({
          path: `/dashboardObject`,
        });
      }
    },
    beforeunloadHandler() {
      sessionStorage.setItem("issave", true);
    },
    inittabstatus() {
      this.clearshowMenu();
      this.clearmoreMenu();
      this.showMenus.forEach((item) => {
        if (item.id == sessionStorage.getItem("applicationTab")) {
          item.isDefaultLaunchTab = "true";
        }
      });
      this.moreMenus.forEach((item) => {
        if (item.id == sessionStorage.getItem("applicationTab")) {
          item.isDefaultLaunchTab = "true";
        }
      });
    },
    //获取左侧应用程序列表
    getapplication() {
      let application = this.$store.state.home.homeApplicationId;
      LayoutApi.getApplicationList().then((res) => {
        if (!res) return;
        let applicationList = res.data;
        this.applicationarr = applicationList;
        //appState = 1为默认显示的程序
        this.defaultid = applicationList.filter((item) => {
          return item.appState == 1000;
        });
        // 判断sessionStorage存储的程序id是否与请求回来的一致
        let findapp = applicationList.some((item) => {
          return item.id == application;
        });
        // 判断用户是否开启服务云
        let isOpenService = applicationList.some((item) => {
          if (item.id === "ace20210219FieldSer") {
            return true;
          }
        });
        sessionStorage.setItem("isOpenService", isOpenService);
        if (application && findapp) {
          //获取菜单
          this.init(application);
          let filtername = applicationList.filter((item) => {
            return item.id == application;
          });
          this.applicationName = filtername[0].name;
          this.logourl = filtername[0].logourl;
        } else {
          if (this.defaultid && this.defaultid[0]) {
            //获取菜单
            if(this.defaultid[0].id != undefined) {
              this.init(this.defaultid[0].id);
            }
            this.applicationName = this.defaultid[0].name;
            this.logourl = this.defaultid[0].logourl;
          } else {
            //获取菜单
            this.init(applicationList[0].id);
            this.applicationName = applicationList[0].name;
            this.logourl = applicationList[0].logourl;
          }
        }
        //获取菜单
        this.init(applicationList[0].id);
        this.applicationName = $i18n.t("label.emailsync.form.seversetting");
        this.logourl = applicationList[0].logourl;
      });
    },

    //计算字符串长度
    strlen(str) {
      let len = 0;
      let copystr = str;
      if (copystr.length > 8) {
        copystr = copystr.substring(0, 7);
      }
      for (let i = 0; i < copystr.length; i++) {
        let c = copystr.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          len++;
        } else {
          len += 2;
        }
      }
      return len;
    },
    //取消或替换临时菜单刷新视图
    canceltemporary() {
      this.moreMenus = [];
      let copyallmenu = JSON.parse(JSON.stringify(this.allMenus));
      this.showMenus = copyallmenu;
      let obj = {}; //全部菜单
      obj.tabName = this.$i18n.t("label.pc.ui.2018.all.tabs");
      obj.id = "allmenu";
      this.moreMenus.push(obj);
      this.clickshowMenu(this.showMenus[0]);
    },
    //如果当前点击项小于替换目标项，直接替换(临时菜单)
    changeshowmenu(tag) {
      setTimeout(() => {
        tag.istemporary = true;
        let lastshowval = this.showMenus.splice(this.showMenus.length - 1, 1);
        this.showMenus.push(tag);
        this.moreMenus.unshift(lastshowval[0]);
      }, 0);
      // this.canceltemporary();
      this.moreMenus = [];
      let copyallmenu = JSON.parse(JSON.stringify(this.allMenus));
      this.showMenus = copyallmenu;
      let obj = {}; //全部菜单
      obj.tabName = this.$i18n.t("label.pc.ui.2018.all.tabs");
      obj.id = "allmenu";
      this.moreMenus.push(obj);
    },
    //如果当前点击项大于替换目标项，计算字数该替换几个(临时菜单)
    changeshowmenus(tag) {
      setTimeout(() => {
        tag.istemporary = true;
        let allTemporst = this.strlen(tag.tabName);
        let showMenusreverse = [];
        this.showMenus.forEach((item) => {
          showMenusreverse.push(item);
        });
        let showMenusreverserr = showMenusreverse.reverse();
        let deleteshowmenuindex = [];
        let indexaddup = 0;
        for (let i = 0; i < showMenusreverserr.length; i++) {
          deleteshowmenuindex.push(showMenusreverserr[i].id);
          indexaddup = indexaddup + this.strlen(showMenusreverserr[i].tabName);
          if (indexaddup >= allTemporst) {
            break;
          }
        }
        deleteshowmenuindex.forEach((item) => {
          let Multiplearr = this.showMenus.findIndex((value) => {
            return value.id == item;
          });
          let Multipleval = this.showMenus.splice(Multiplearr, 1);
          this.moreMenus.unshift(Multipleval[0]);
        });
        this.showMenus.push(tag);
      }, 0);
      // this.canceltemporary();
      this.moreMenus = [];
      let copyallmenu = JSON.parse(JSON.stringify(this.allMenus));
      this.showMenus = copyallmenu;
      let obj = {}; //全部菜单
      obj.tabName = this.$i18n.t("label.pc.ui.2018.all.tabs");
      obj.id = "allmenu";
      this.moreMenus.push(obj);
    },
    //切换应用程序
    tabapplication(value, logourl) {
      sessionStorage.setItem("issave", false);
      this.RefreshSelect = true;
      this.moreMenus = [];
      this.menuprops = value;
      this.logourl = logourl;
      /**
       * 系统设置页切换应用程序不走初始化，直接进应用程序的首页
       */
      this.$router.push({
        path: `/`,
      });
      //获取菜单
      // this.init(this.menuprops);
      // this.$refs.topMenu.getmymenusetting();
    },
    //子组件调用刷新视图
    Refreshapplicationview(id) {
      this.init(id);
    },
    // 页面初始化
    init(val) {
      this.moreMenus = [];
      localStorage.setItem("application", val);
      this.showMenus =
        this.allMenus =
        this.allmenulist =
          [
            {
              displaytype: null,
              id: "systemSettings",
              isDefaultLaunchTab: "true",
              iscustom: "0",
              isquickcreated: null,
              lightningpageId: null,
              lightningpage_id: null,
              mobileimg: null,
              name: "home",
              objId: null,
              obj_id: null,
              objectApi: "",
              object_api: "",
              pageUrl: null,
              page_url: null,
              prefix: "",
              seq: 1,
              tabName: $i18n.t("vue_label_systemSettings_setHomePage"), //设置主页
              tabStyle: "hometab_norm",
              tab_name: $i18n.t("vue_label_systemSettings_setHomePage"), //设置主页
              tab_style: "hometab_norm",
              type: null,
              url: "/systemSettings",
              vpageid: null,
            },
          ];

      let Isincluded = this.allmenulist.some((item) => {
        return item.id == sessionStorage.getItem("applicationTab");
      });
      if (sessionStorage.getItem("applicationTab") && Isincluded) {
        // 保存选项卡id到sessionStorage
        let Isincludedmenu = this.showMenus.filter(
          (item) => item.id == sessionStorage.getItem("applicationTab")
        );
        this.startmenu = Isincludedmenu[0];
      } else {
        this.startmenu = this.allmenulist[0];
      }
      let obj = {}; //全部菜单
      obj.tabName = this.$i18n.t("label.pc.ui.2018.all.tabs");
      obj.id = "allmenu";
      this.moreMenus.push(obj);
      // 是否跳转到记录菜单 issave为true时跳转
      if (sessionStorage.getItem("issave") === "false") {
        if (this.RefreshSelect) {
          this.clickshowMenu(this.showMenus[0]);
          this.$bus.$emit("refareshPage");
          this.RefreshSelect = false;
        } else {
          this.clickshowMenu(this.startmenu);
        }
      }
      if (
        sessionStorage.getItem("applicationTab") == null ||
        sessionStorage.getItem("applicationTab") == "" ||
        sessionStorage.getItem("applicationTab") == "none"
      ) {
        // 右键打开新窗口，toHome为true时跳转到菜单第一个
        // 如果一开始加载的页面时主页就让顶部top菜单的主页选中
        if (this.$route.path === "/homePageObject/standardPage") {
          this.clickshowMenu(this.showMenus[0]);
        }
      }
      this.inittabstatus();
      this.RefreshSelect = false;
    },
    // 切换展示菜单选项卡（应用程序和菜单）
    clickshowMenu: throttle(function (tag) {
      // lightning版本自定义页面
      if (
        tag.lightningpageId !== undefined &&
        tag.lightningpageId !== null &&
        tag.lightningpageId !== "null" &&
        tag.lightningpageId !== ""
      ) {
        VueCookies.set("customePageUrl", tag.pageUrl, { sameSite: 'Strict' });
        if (this.$route.path === "/custome/customePage") {
          Bus.$emit("changeCustomePageUrl", tag.pageUrl);
        } else {
          // lightningpageId判断页面
          if (tag.lightningpageId.split("#")[1] == "lightning") {
            this.$router.push({
              path: "/injectionComponent",
              query: {
                id: "00420219A606334Muoia",
                page: tag.lightningpageId.split("#")[0],
                button: "Home",
              },
            });
          } else {
            this.$router.push(`/custome/customePage`);
          }
        }
      } else if (tag.type == "page") {
        let symbol;
        if (tag.url.indexOf("?") != -1) {
          symbol = "&";
        } else {
          symbol = "?";
        }
        window.open(
          `${this.userurl}${
            tag.url
          }${symbol}version=version2018&binding=${this.$CCDK.CCToken.getToken()}`
        );
      } else if (tag.type == "web") {
        window.location.href = tag.url;
      } else {
        this.clearshowMenu();
        this.clearmoreMenu();
        this.showMenus.forEach((item) => {
          if (item.id === tag.id) {
            item.isDefaultLaunchTab = "true";
            // 判断是否通用对象 1 表示不是自定义对象
            if (item.id == "p1020207266DDC2nZ3Jp") {
              this.$router.push({
                path: `/actualworklist/listviews`,
              });
              return;
            } else if (item.id == "acfganttchart001") {
              this.$router.push({
                path: `/gantt/gantt`,
              });
            }
            // else if (item.id === "acf2011000053244B1ew") {
            //   // 个案详情
            //   this.$router.push({
            //     path: `/controlPanel/control`,
            //   });
            // }
            else if (item.id === "acfShiftstdtab00") {
              this.$router.push({
                path: `/commonObjects/views/plan/${item.objectApi}/${item.objId}/${item.prefix}`,
              });
            } else if (item.type === "object") {
              this.$router.push({
                path: `/commonObjects/views/table/${item.objectApi}/${item.objId}/${item.prefix}`,
              });
            } else if (item.id === "acf2020340C93FD7chBP") {
              // 标情发现
              this.$router.push({
                path: `/moodFound`,
              });
              //主页
            } else if (item.id == "acf2021070mcforms") {
              // 市场活动 表单
              this.$router.push({
                path: `/marketForm/marketForm`,
              });
            } else if (item.id == "acf2021070mcsocial") {
              // 市场云社交媒体
              this.$router.push({
                path: `/social/social`,
              });
            } else if (item.id === "acf100000") {
              this.$router.push({
                path: `/reportObject/reportList`,
              });
            } else if (item.id === "acf000001") {
              this.$router.push({
                path: `/homePageObject`,
              });
            } else if (item.id === "acf100009") {
              // this.getDashboardList();
              this.$router.push({
                path: `/dashboardObject`,
              });
            } else if (
              //日历
              item.id === "acf20200615event" ||
              item.id === "acf20208AD53CC8sLvgf"
            ) {
              this.$router.push({
                path: `/CalendarHeader`,
              });
              //文件
            } else if (item.id === "acf20171206fileUjdys") {
              this.$router.push({
                path: `/ccFileCenter`,
              });
              //邮件
            } else if (item.id === "acf20191021FeiAUjdys") {
              this.$router.push({
                path: `/relation`,
              });
              //同事圈
            } else if (item.id === "acf201200141050RgtYi") {
              this.$router.push({
                path: `/colleagueCircle`,
              });
            } else if (item.id == "acf2015030630xsld") {
              this.$router.push({
                path: `/commonObjects/hopper`,
              });
            } else if (item.id == "acf2020110PriceBooks") {
              this.$router.push({
                path: `/priceManual/price-manual`,
              });
            } else if (item.id == "acfTargetmodelstdtab") {
              this.$router.push({
                path: `/targetModel/index`,
              });
            } else if (item.id == "acfTargetstdtab00") {
              this.$router.push({
                path: `/target/index`,
              });
            } else if (item.id == "acf20201216smslist") {
              this.$router.push({
                path: `/noteSms/noteSmsList`,
              });
            } else if (item.id === "acf202011leadmksea") {
              // 公海池部分
              // 潜在客户公海池
              this.$router.push({
                path: `/clientPool/cloudcclead/lead/004`,
              });
            } else if (item.id === "acf202011accmksea") {
              // 客户公海池
              this.$router.push({
                path: `/clientPool/Account/account/001`,
              });
              //派工平台
            } else if (item.id === "acfFieldServiceSystb") {
              this.$router.push({
                path: `/dispatchingUnits`,
              });
              //小组
            } else if (item.id === "acf20190807sgeAUjdys") {
              this.$router.push({
                path: `/groupObject/groupList`,
              });
              //人员资源日历
            } else if (item.id === "acf20219D0E7E25g4uUv") {
              this.$router.push({
                path: `/resoursecalendar/resoursecalendar`,
              });
            }
            // 预测
            else if (item.id === "acf2013121431foreca") {
              this.$router.push({
                path: `/forecast/forecast`,
              });
              //伙伴云-订货平台
            } else if (item.id === "acf20210713orderpro1") {
              this.$router.push({
                path: `/ordering`,
              });
            }
            // 系统设置页（写死发布）
            else if (item.id === "systemSettings") {
              this.$router.push({
                path: `/systemSettings`,
              });
            } else {
              this.$router.push({
                path: `/`,
              });
            }
          }
        });
        sessionStorage.setItem("applicationTab", tag.id);
      }
    }, 3000),
    //切换更多菜单选项卡
    
    clickmoreMenu(tag) {
      // lightning版本自定义页面
      if (
        tag.lightningpageId !== undefined &&
        tag.lightningpageId !== null &&
        tag.lightningpageId !== "null" &&
        tag.lightningpageId !== ""
      ) {
        VueCookies.set("customePageUrl", tag.pageUrl, { sameSite: 'Strict' });
        if (this.$route.path === "/custome/customePage") {
          Bus.$emit("changeCustomePageUrl", tag.pageUrl);
        } else {
          this.$router.push(`/custome/customePage`);
        }
      } else if (tag.id != "allmenu") {
        if (tag.type == "page") {
          let symbol;
          if (tag.url.indexOf("?") != -1) {
            symbol = "&";
          } else {
            symbol = "?";
          }
          window.open(
            `${this.userurl}${
              tag.url
            }${symbol}version=version2018&binding=${this.$CCDK.CCToken.getToken()}`
          );
        } else if (tag.type == "web") {
          window.location.href = tag.url;
        } else {
          this.clearshowMenu();
          this.clearmoreMenu();
          this.moreMenus.forEach((item) => {
            if (item.id == tag.id) {
              item.isDefaultLaunchTab = "true";
              if (item.id == "p1020207266DDC2nZ3Jp") {
                this.$router.push({
                  path: `/actualworklist/listviews`,
                });
                return;
              } else if (item.id == "acfganttchart001") {
                this.$router.push({
                  path: `/gantt/gantt`,
                });
              } else if (item.id === "acf20207266DDCLKAIQ1") {
                // 知识文章
                this.$router.push({
                  path: `/Knowledgearticles/CloudccKArticle/cloudcc_k_article/k02`,
                });
              }
              //   else if (item.id === "acf2011000053244B1ew") {
              //   // 个案详情
              //   this.$router.push({
              //     path: `/controlPanel/control`,
              //   });
              // }
              else if (item.id === "acfShiftstdtab00") {
                this.$router.push({
                  path: `/commonObjects/views/plan/${item.objectApi}/${item.objId}/${item.prefix}`,
                });
              } else if (item.type === "object") {
                this.$router.push({
                  path: `/commonObjects/views/table/${item.objectApi}/${item.objId}/${item.prefix}`,
                });
              } else if (item.id === "acf2020340C93FD7chBP") {
                // 标情发现
                this.$router.push({
                  path: `/moodFound`,
                });
              } else if (item.id == "acf2021070mcforms") {
                //市场活动表单
                return `/#/marketForm/marketForm`;
              } else if (item.id == "acf2021070mcsocial") {
                //市场云社交媒体
                return `/#/social/social`;
              } else if (item.id === "acf100000") {
                this.$router.push({
                  path: `/reportObject/reportList`,
                });
              } else if (item.id === "acf000001") {
                this.$router.push({
                  path: `/homePageObject`,
                });
              } else if (item.id === "acf100009") {
                // this.getDashboardList();
                this.$router.push({
                  path: `/dashboardObject`,
                });
              } else if (
                item.id === "acf20200615event" ||
                item.id === "acf20208AD53CC8sLvgf"
              ) {
                this.$router.push({
                  path: `/CalendarHeader`,
                });
              } else if (item.id === "acf20171206fileUjdys") {
                this.$router.push({
                  path: `/ccFileCenter`,
                });
              } else if (item.id === "acf20191021FeiAUjdys") {
                this.$router.push({
                  path: `/relation`,
                });
              } else if (item.id === "acf201200141050RgtYi") {
                this.$router.push({
                  path: `/colleagueCircle`,
                });
              } else if (item.id == "acf2015030630xsld") {
                this.$router.push({
                  path: `/commonObjects/hopper`,
                });
              } else if (item.id == "acf2020110PriceBooks") {
                this.$router.push({
                  path: `/priceManual/price-manual`,
                });
              } else if (item.id == "acfTargetmodelstdtab") {
                this.$router.push({
                  path: `/targetModel/index`,
                });
              } else if (item.id == "acfTargetstdtab00") {
                this.$router.push({
                  path: `/target/index`,
                });
              } else if (item.id == "acf20201216smslist") {
                this.$router.push({
                  path: `/noteSms/noteSmsList`,
                });
              } else if (item.id === "acf202011leadmksea") {
                // 公海池部分
                // 潜在客户公海池
                this.$router.push({
                  path: `/clientPool/cloudcclead/lead/004`,
                });
              } else if (item.id === "acf202011accmksea") {
                // 客户公海池
                this.$router.push({
                  path: `/clientPool/Account/account/001`,
                });
              } else if (item.id === "acfFieldServiceSystb") {
                //派工平台
                this.$router.push({
                  path: `/dispatchingUnits`,
                });
                //小组
              } else if (item.id === "acf20190807sgeAUjdys") {
                this.$router.push({
                  path: `/groupObject/groupList`,
                });
              } else if (item.id === "acf2013121431foreca") {
                //预测
                this.$router.push({
                  path: `/forecast/forecast`,
                });
              } else if (item.id === "acf20219D0E7E25g4uUv") {
                this.$router.push({
                  path: `/resoursecalendar/resoursecalendar`,
                });
              } //伙伴云-订货平台
              else if (item.id === "acf20210713orderpro1") {
                this.$router.push({
                  path: `/ordering`,
                });
              } else {
                this.$router.push({
                  path: `/`,
                });
              }
            }
          });
          sessionStorage.setItem("applicationTab", tag.id);
        }
      } else {
        this.$refs.topMenu.showallmenu();
      }
    },
    //清除选项卡选中状态
    clearshowMenu() {
      this.showMenus.forEach((item) => {
        item.isDefaultLaunchTab = "false";
      });
    },
    clearmoreMenu() {
      this.moreMenus.forEach((item) => {
        item.isDefaultLaunchTab = "false";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-grid-item > .vue-resizable-handle {
  z-index: 999;
}
.home_content {
  height: 100vh;
  min-width: 1000px;
  /* padding: 10px; */
  .el-header {
    height: auto !important;
    padding: 0;
  }
  .settingContent {
    display: flex;
    background: #20233a;
    justify-content: space-between;
  }
  .el-main {
    // background: -webkit-linear-gradient(top, #2f6ba1, #b0c4df);
    background: #f0f0f0;
    padding: 0;
    overflow: auto;
  }
}
.isShowprobation2 {
  height: calc(100vh - 42px);
}
#boxFixed {
  height: 428px;
}
</style>
